import React from "react";

const Footer = () => {
  return (
    <footer className="container-fluid">
      <nav
        className="navbar navbar-expand-lg col-sm-auto col-12"
        aria-label="Bottom navigation"
      >
        <ul className="navbar-nav hstack row-gap-1 column-gap-3 flex-wrap">
          <li className="nav-item nav-item-menu">
            <a className="nav-link" href="#features">
              Features
            </a>
          </li>
          <li className="nav-item nav-item-menu">
            <a className="nav-link" href="#benefits">
              Benefits
            </a>
          </li>
          <li className="nav-item nav-item-menu">
            <a className="nav-link" href="#team">
              Team
            </a>
          </li>
          <li className="nav-item nav-item-menu">
            <a
              className="nav-link"
              href="#faq"
              aria-label="Frequently Asked Questions"
            >
              FAQ
            </a>
          </li>
        </ul>

        <div className="footer-copyrights col-sm-auto col-12">
          <span>© Copyright 2024 - Daysketcher</span>
          <span>
            <a href="DaysketcherTermsofService.html" target="_blank">
              Terms of Service
            </a>{" "}
            |{" "}
            <a href="DaysketcherPrivacyPolicy.html" target="_blank">
              Privacy Policy
            </a>
          </span>
        </div>

        <div className="hstack gap-3 col-sm-auto col-12 justify-content-center">
          <a
            className="instagram-button"
            href="https://www.instagram.com/daysketcher_app/"
            aria-label="Find more about us on Instagram"
            target="_blank"
          >
            <i className="fa-brands fa-2x fa-instagram text-white"></i>
          </a>
          <a
            className="facebook-button"
            href="https://www.facebook.com/daysketcher"
            aria-label="Find more about us on Facebook"
            target="_blank"
          >
            <i className="fa-brands fa-2x fa-facebook text-white"></i>
          </a>
          <a
            className="twitter-button"
            href="https://twitter.com/daysketcher_app"
            aria-label="Find more about us on Twitter"
            target="_blank"
          >
            <i className="fa-brands fa-2x fa-x-twitter text-white"></i>
          </a>
          <a
            className="linkedin-button"
            href="https://www.linkedin.com/company/daysketcher/"
            aria-label="Find more about us on LinkedIn"
            target="_blank"
          >
            <i className="fa-brands fa-2x fa-linkedin text-white"></i>
          </a>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
