import React from 'react';
import "./App.scss";
import Header from './components/Header/Header'
import Hero from './components/Hero/Hero';
import Features from './components/Features/Features';
import PlannedFeatures from './components/PlannedFeatures/PlannedFeatures';
import DaysketcherWorld from './components/DaysketcherWorld/DaysketcherWorld';
import Benefits from './components/Benefits/Benefits';
import Team from './components/Team/Team';
import Registration from './components/Registration/Registration';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';

function App() { 
  return (
    <div className="container-fluid px-0">
      <Header/>
      <main id="ds-main">
        <Hero />
        <Features/>
        <PlannedFeatures/>
        <DaysketcherWorld/>
        <Benefits/>
        <Team/>
        <FAQ/>
        <Registration/>
      </main>
      <Footer/>
    </div>
    
  );
}

export default App;
