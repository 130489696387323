import React from "react";
import Interactions from "../Interactions/Interactions";

const Benefits = () => {
  return (
    <section id="benefits" className="container" aria-label="Benefits">
      <span className="section-caption">Benefits</span>
      <h2 className="mb-5 pb-5">How you can benefit using Daysketcher</h2>

      <div className="grid">
        <Interactions
          animationClass="fade-in-animation"
          className="card g-col-lg-3 g-col-md-6 g-col-12 text-body-primary"
        >
          <div className="card-body">
            <div className="card-icon">
              <img
                src="assets/images/rocket.svg"
                alt="Rocket, representing mood boost and stress reduce"
                height="40px"
                width="40px"
              />
            </div>
            <h3 className="card-title">Boost your mood and reduce stress</h3>
            <p className="card-text">
              30% decrease in stress after using Daysketcher 4 weeks
            </p>
          </div>
        </Interactions>
        <Interactions
          animationClass="fade-in-animation"
          className="card g-col-lg-3 g-col-md-6 g-col-12 text-body-primary"
        >
          <div className="card-body">
            <div className="card-icon">
              <img
                src="assets/images/growth.svg"
                alt="Growing tree"
                height="40px"
                width="40px"
              />
            </div>
            <h3 className="card-title">
              Cultivate gratitude to positive mindset to enhance well being
            </h3>
            <p className="card-text">
              25% increase in overall well being after 6 weeks
            </p>
          </div>
        </Interactions>
        <Interactions
          animationClass="fade-in-animation"
          className="card g-col-lg-3 g-col-md-6 g-col-12 text-body-primary"
        >
          <div className="card-body">
            <div className="card-icon">
              <img
                src="assets/images/love.svg"
                alt="Self care"
                height="40px"
                width="40px"
              />
            </div>
            <h3 className="card-title">Improve self-esteem to confidence</h3>
            <p className="card-text">
              20% increased self-esteem after reflective work
            </p>
          </div>
        </Interactions>
        <Interactions
          animationClass="fade-in-animation"
          className="card g-col-lg-3 g-col-md-6 g-col-12 text-body-primary"
        >
          <div className="card-body">
            <div className="card-icon">
              <img
                src="assets/images/progress.svg"
                alt="Icon showing growth and progress"
                height="40px"
                width="40px"
              />
            </div>
            <h3 className="card-title">
              Increase productivity by starting your day on a positive note
            </h3>
            <p className="card-text">
              15% increase in productivity after morning use
            </p>
          </div>
        </Interactions>
      </div>
    </section>
  );
};

export default Benefits;
