import React from 'react';

const Team = () => {
    return (
        <section id="team" className="container-fluid">
            <span className="section-caption">Team</span>
            <h2 className='mb-5'>Meet the team</h2>
            <div className="grid container">
                <div className="g-col-md-6 g-col-12 team-card">
                    <img src="assets/images/Tomislav-Petric.png" alt="Image from our CEO, Tomislav Petric" height="132px"
                    width="132px"/>
                    <div className="team-card-body">
                    <span className="team-card-caption">CEO & FOUNDER</span>
                    <h3 className="team-card-title">Tomislav Petric</h3>
                    <p>Expertise in business and financials with 1 successful exit <a href="https://www.vpv.hr/">(https://www.vpv.hr/)</a></p>
                    <div className="team-card-icons">
                        <a href="https://www.f6s.com/member/tomislavpetric#about"
                        aria-label="Connect with Tomislav Petric on LinkedIn"><i className="fa-brands fa-linkedin"></i></a>
                        <a href="mailto:tomislav.petric@daysketcher.com" aria-label="Send email to Tomislav Petric"><i
                            className="fa-regular fa-envelope"></i></a>
                    </div>
                    </div>
                </div>
                <div className="g-col-md-6 g-col-12 team-card">
                    <img src="assets/images/Damjan-Miloshevki.png" alt="Image from our CTO, Damjan Miloshevki" height="132px"
                    width="132px"/>
                    <div className="team-card-body">
                    <span className="team-card-caption">CTO & CO-FOUNDER</span>
                    <h3 className="team-card-title">Damjan Miloshevski</h3>
                    <p>Expertise in mobile development, Android and Flutter</p>
                    <div className="team-card-icons">
                        <a href="https://www.linkedin.com/in/damjanmiloshevski/"
                        aria-label="Connect with Damjan Miloshevski on LinkedIn"><i className="fa-brands fa-linkedin"></i></a>
                        <a href="mailto:damjan.miloshevski@daysketcher.com" aria-label="Send email to Damjan Miloshevski"><i
                            className="fa-regular fa-envelope"></i></a>
                    </div>
                    </div>
                </div>
                <div className="g-col-md-6 g-col-12 team-card">
                    <img src="assets/images/Miroslav-Popovic.png"
                    alt="Image from our Big Data Architect and board member, Miroslav Popovic" height="132px" width="132px"/>
                    <div className="team-card-body">
                    <span className="team-card-caption">Board member & CO-FOUNDER</span>
                    <h3 className="team-card-title">Miroslav Popovic</h3>
                    <p>Expertise in Big Data and Micro-services Architect</p>
                    <div className="team-card-icons">
                        <a href="https://www.linkedin.com/in/miroslav-popovic-a962224/"
                        aria-label="Connect with Miroslav Popovic on LinkedIn"><i className="fa-brands fa-linkedin"></i></a>
                        <a href="mailto:miroslav.popovic@daysketcher.com" aria-label="Send email to Miroslav Popovic"><i
                            className="fa-regular fa-envelope"></i></a>
                    </div>
                    </div>
                </div>
                <div className="g-col-md-6 g-col-12 team-card">
                    <img src="assets/images/Katerina-Shalevska.png" alt="Image from our CBO, Katerina Shalevska" height="128px"
                    width="132px"/>
                    <div className="team-card-body">
                    <span className="team-card-caption">CBO & CO-FOUNDER</span>
                    <h3 className="team-card-title">Katerina Shalevska</h3>
                    <p>Expertise in UX & Project Management</p>
                    <div className="team-card-icons">
                        <a href="https://www.linkedin.com/in/katerina-shalevska/"
                        aria-label="Connect with Katerina Shalevska on LinkedIn"><i className="fa-brands fa-linkedin"></i></a>
                        <a href="mailto:katerina.shalevska@daysketcher.com" aria-label="Send email to Katerina Shalevska"><i
                            className="fa-regular fa-envelope"></i></a>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Team;