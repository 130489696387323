import React from "react";
import Interactions from "../Interactions/Interactions";

const PlannedFeatures = () => {
  return (
    <section id="planned-features" className="container">
      <span className="section-caption">Planned features</span>
      <h2 className="mb-5">Integration with your favorite application</h2>
      <div className="grid integration-app-card-wrapper">
      <Interactions animationClass="zoom-in-animation" className="g-col-md-3 g-col-6 integration-app-card">
            <div className="g-col-2">
              <img
                src="assets/images/color-icon-facebook.svg"
                alt="Facebook Icon"
                width="32px"
                height="32px"
              />
            </div>
            <div className="integration-app-card-body">
              <h3>Facebook</h3>
              <p>Posts & Media</p>
            </div>
        </Interactions>
        <Interactions animationClass="zoom-in-animation" className="g-col-md-3 g-col-6 integration-app-card">
            <div className="g-col-2">
              <img
                src="assets/images/color-icon-instagram.svg"
                alt="Instagram Icon"
                width="32px"
                height="32px"
              />
            </div>
            <div className="integration-app-card-body">
              <h3>Instagram</h3>
              <p>Saved & Media</p>
            </div>
        </Interactions>
        <Interactions animationClass="zoom-in-animation" className="g-col-md-3 g-col-6 integration-app-card">
            <div className="g-col-2">
              <img
                src="assets/images/color-icon-spotify.svg"
                alt="Spotify Icon"
                width="32px"
                height="32px"
              />
            </div>
            <div className="integration-app-card-body">
              <h3>Spotify</h3>
              <p>Favorite Music</p>
            </div>
        </Interactions>
        <Interactions animationClass="zoom-in-animation" className="g-col-md-3 g-col-6 integration-app-card">
            <div className="g-col-2">
              <img
                src="assets/images/color-icon-tiktok.svg"
                alt="Tik Tok Icon"
                width="32px"
                height="32px"
              />
            </div>
            <div className="integration-app-card-body">
              <h3>TikTok</h3>
              <p>Favorite Reels</p>
            </div>
        </Interactions>
      </div>
    </section>
  );
};

export default PlannedFeatures;
