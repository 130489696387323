import React, { useEffect, useRef } from 'react';

function useIntersectionObserver(animationClass: string) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass);
          return;
        }

        entry.target.classList.remove(animationClass);
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [animationClass]);

  return ref;
}

function Interactions({ animationClass, className, children }: { animationClass: string, className?: string, children: React.ReactNode }) {
  const ref = useIntersectionObserver(animationClass);

  return <div className={className} ref={ref}>{children}</div>;
}

export default Interactions;
