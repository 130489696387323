import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import Flickity from "react-flickity-component";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import Interactions from "../Interactions/Interactions";
import "./Hero.scss";
import "flickity/dist/flickity.min.css";

const Hero = () => {
  const flickityOpts = {
    initialIndex: 3,
    prevNextButtons: true,
    cellSelector: "img", // Selects img elements inside the carousel as cells
    imagesLoaded: true, // Ensures images are loaded before initializing Flickity
    percentPosition: false,
    autoPlay: 5000,
    cellAlign: "left",
    contain: true,
    adaptiveHeight: true,
  };

  const handleSlideMove = (e: any) => {
    // analytics.logEvent('',{})
    logEvent(analytics, 'carousel_change', {
      index: carouselRef.current?.selectedIndex,
      eventType: 'click'
    })
  }

  let [caption, setCaption] = useState("Pairing Memories to Boost Mood");
  const carouselRef = useRef<Flickity>();

  useEffect(() => {
    
    setTimeout(() => {
      const prevButton = document.querySelector(".flickity-prev-next-button.previous");
      const nextButton = document.querySelector(".flickity-prev-next-button.next");
      if(prevButton) prevButton.addEventListener('click', handleSlideMove);
      if(nextButton) nextButton.addEventListener('click', handleSlideMove);
    }, 100 );
    
  },[])

  const handleOnChange = React.useCallback((event: SyntheticEvent) => {
    setCaption((carouselRef.current?.selectedElement as any)?.alt);
  }, []);

  const setFlickityRef = React.useCallback((ref: Flickity) => {
    carouselRef.current?.off("change", handleOnChange);

    carouselRef.current = ref;

    carouselRef.current?.on("change", handleOnChange);
  }, []);

  return (
    <section id="hero" className="hero">
      <div className="grid text-center">
        <div className="g-col-12">
          <h1
            aria-label="Daysketcher, store your moments, unlock your emotions"
            className="hero-title"
          >
            <Interactions animationClass="slide-in-down-animation">
              <span className="manrope-bold title-1">Store your moments,</span>
            </Interactions>
            <Interactions animationClass="slide-in-up-animation">
              <span className="manrope-light title-2">
                unlock your emotions.
              </span>
            </Interactions>
          </h1>

          <div className="grid text-center my-md-5 my-3">
            <p className="g-col-md-6 g-col-12 g-start-md-4 px-3">
              <strong>Claim your free spot</strong> on our waitlist today! Join
              now and secure your <strong>6 months free</strong> subscription
              with exclusive beta access.
              <br />
              <small>Embrace every emotion, every day!</small>
            </p>
          </div>
          <button
            type="button"
            className="btn btn-dark mb-sm-4"
            onClick={() => document.getElementById("submit")?.scrollIntoView()}
          >
            Join the waitlist
          </button>
        </div>

        <div className="dm-width g-col-12  hero-child">
          <div className="dm-device">
            <div className="device">
              <div className="screen">
                <Flickity
                  className="main-carousel"
                  flickityRef={setFlickityRef}
                  elementType={"div"} // default 'div'
                  options={flickityOpts} // takes flickity options {}
                  disableImagesLoaded={false} // default false
                  reloadOnUpdate={false} // default false
                  static // default false
                >
                  <img
                    src="assets/images/notifications.png"
                    className="carousel-cell"
                    alt="Notifications for journaling"
                  />
                  <img
                    src="assets/images/home.png"
                    className="carousel-cell"
                    alt="Journaling"
                  />
                  <img
                    src="assets/images/mood-journal.png"
                    className="carousel-cell"
                    alt="Overall daily mood journal"
                  />
                  <img
                    src="assets/images/matched.png"
                    className="carousel-cell"
                    alt="Pairing Memories to Boost Mood"
                  />
                  <img
                    src="assets/images/emotion-journal.png"
                    className="carousel-cell"
                    alt="Emotional Journal"
                  />
                  <img
                    src="assets/images/memory.png"
                    className="carousel-cell"
                    alt="Collect & Store Memories"
                  />
                  <img
                    src="assets/images/statistics.png"
                    className="carousel-cell"
                    alt="Mood Tracking"
                  />
                </Flickity>
              </div>
            </div>
          </div>
          <p className="caption">{caption}</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
