import React, { useEffect, useRef } from "react";
import "./Header.scss";

const Header = () => {
  const navBarTogglerRef = useRef(null);
  useEffect(() => {
    const handleNavToggle = () => {
      const navbarTop = document.getElementById("header-navbar");
      navbarTop?.classList.toggle(
        "show",
        document
          .querySelector(".navbar-toggler")
          ?.getAttribute("aria-expanded") === "true"
      );
    };

    const handleScroll = () =>
      document
        .querySelector(".navbar")
        ?.classList.toggle("scroll", window.scrollY > 0);

    const handleLoad = () => handleScroll();

    document
      .querySelector(".navbar-toggler")
      ?.addEventListener("click", handleNavToggle);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleLoad);

    return () => {
      document
        .querySelector(".navbar-toggler")
        ?.removeEventListener("click", handleNavToggle);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <header id="header">
      <a href="#ds-main" className="visually-hidden-focusable">
        Skip to main content
      </a>

      <nav
        className="navbar navbar-expand-lg fixed-top"
        id="header-navbar"
        aria-label="Top navigation"
      >
        <div className="container-fluid px-0 px-sm-2">
          <div className="hstack flex-grow-1 justify-content-between">
            <a href="#" className="navbar-brand">
              <img
                src="assets/images/daysketcher-new-logo.svg"
                alt="Daysketcher Logo"
                width="100%"
                height="100%"
              />
            </a>
            <button
              className="navbar-toggler btn-link border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mt-2">
              <li className="nav-item nav-item-menu">
                <a className="nav-link" href="#features">
                  Features
                </a>
              </li>
              <li className="nav-item nav-item-menu">
                <a className="nav-link" href="#benefits">
                  Benefits
                </a>
              </li>
              <li className="nav-item nav-item-menu">
                <a className="nav-link" href="#team">
                  Team
                </a>
              </li>
              <li className="nav-item nav-item-menu">
                <a className="nav-link" href="#faq">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
